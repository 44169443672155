<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path id="Path_99" data-name="Path 99"
      d="M-.674,1473.673l-3.611-6,3.611-6-6,3.611-6-3.611,3.611,6-3.611,6,5.965-3.59h0l.035-.021Z"
      transform="translate(12.674 -1461.673)" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: 'Deco',
  props: {
    fill: {
      type: String,
      default: '#d30025',
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
