<template>
  <div class="title-main">
    <div
      class="title_text-main txt-main h2"
      :class="[
        leftDecor ? 'leftDecor' : '',
        rightDecor ? 'rightDecor' : '',
      ]"
    >
      {{ textMain }}
    </div>
    <div class="title_text-sub h6 txt-black txt-600">{{ textSub }}</div>
  </div>
</template>

<script>
export default {
  name: 'TitleMain',
  props: {
    textMain: {
      type: String,
      default: '',
    },
    textSub: {
      type: String,
      default: '',
    },
    leftDecor: {
      type: Boolean,
      default: false,
    },
    rightDecor: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.title-main {
  display: inline-block;
  .title_text-main {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    &::before, &::after {
      content: "";
      width: 12px;
      height: 12px;
      background-color: $color-red;
      position: absolute;
      top: 50%;
      opacity: 0;
    }
    &.leftDecor {
      &::before {
        opacity: 1;
        left: 0;
        transform: translate(-200%, -50%);
      }
    }
    &.rightDecor {
      &::after {
        opacity: 1;
        right: 0;
        transform: translate(200%, -50%);
      }
    }
  }
}
</style>
