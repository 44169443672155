<template>
  <button class="btn-main" :class="`type_${btnType}`">
    <div class="btn_content">
      {{ text }}
    </div>
    <div class="fake_border"></div>
  </button>
</template>

<script>
export default {
  name: 'BtnMain',
  props: {
    text: {
      type: String,
      default: '',
    },
    btnType: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/helper/_variable.scss';
.btn-main {
  position: relative;
  .btn_content {
    padding: 10px;
    width: 200px;
    border: 1px solid;
    clip-path: polygon(0% 0%, 83% 0%, 100% 36%, 100% 100%, 0% 100%);
    z-index: 2;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0% 0%, 84% 0%, 100% 34%, 100% 100%, 0% 100%);
    background-color: $color-main;
    display: none;
    z-index: 0;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(86% 0, 100% 0, 100% 29%);
    transition: right linear 0.1s;
    z-index: 1;
  }
  @media screen and (min-width: 1024px) {
    &:hover {
      &::before {
        display: block;
      }
      &::after {
        right: -5px;
      }
    }
  }
  &.type_1 {
    .btn_content {
      color: #fff;
      background-color: $color-red;
      border-color: $color-red;
    }
    &::after {
      background-color: $color-main;
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        .btn_content {
          color: $color-main;
          background-color: #fff;
          border-color: $color-main;
        }
      }
    }
  }
  &.type_2 {
    .btn_content {
      color: #fff;
      background-color: transparent;
      border-color: #fff;
    }
    .fake_border {
      position: absolute;
      top: 18px;
      right: 0.5px;
      width: 37.5px;
      height: 1px;
      background-color: #fff;
      transform: rotate(29deg);
      transform-origin: right;
    }
    &::before {
      background-color: #fff;
    }
    &::after {
      background-color: #fff;
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        .btn_content {
          color: $color-red;
          background-color: #fff;
          border-color: #fff;
        }
      }
    }
  }
  &.type_3 {
    .btn_content {
      color: #fff;
      background-color: $color-main;
      border-color: $color-main;
    }
    &::before {
      background-color: $color-main;
    }
    &::after {
      background-color: $color-main;
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        .btn_content {
          color: $color-main;
          background-color: #fff;
        }
      }
    }
  }
}
</style>
