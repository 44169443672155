<template>
  <div class="about">
    <Banner
      title="ABOUT US"
      subtitle="關於六協"
      :img="bannerImg"
    />
    <div class="container">
      <!-- 經營理念 -->
      <Spirit/>
      <!-- 公司特色 -->
      <Features />
      <!-- 產品製程 -->
      <Process/>
      <!-- 未來展望 -->
      <Prospect/>
      <!-- 產品 -->
      <Products />
    </div>
  </div>
</template>

<script>
import bannerImg from '@/assets/imgs/banner/banner_about.png';
import Banner from '@/components/banner/Index.vue';
import Spirit from '@/views/about/Spirit.vue';
import Features from '@/views/about/Features.vue';
import Process from '@/views/about/Process.vue';
import Prospect from '@/views/about/Prospect.vue';
import Products from '@/views/about/Products.vue';

export default {
  name: 'About',
  components: {
    Banner,
    Spirit,
    Features,
    Process,
    Prospect,
    Products,
  },
  data() {
    return {
      bannerImg,
    };
  },
};
</script>

<style lang="scss" scoped>
.about {
  background-color: #f6f6f6;
}
</style>
